.variables {
    --width: 110px;
    --height: calc(var(--width) * 0.866);
    --border-thickness: 3px;
}

.hex {
    display: block;
    margin: 0 auto;
    position: relative;
    width: var(--width);
    height: var(--height);
    background: var(--yellow);
    box-sizing: border-box;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.hex-background {
    position: absolute;
    background-color: var(--light-yellow); /*color of the main-background*/
    top: var(--border-thickness); /* equal to border thickness */
    left: var(--border-thickness); /* equal to border thickness */
    width: calc(var(--width) - 2 * var(--border-thickness)); /* container width - (border thickness * 2) */
    height: calc(var(--height) - 2 * var(--border-thickness)); /* container height - (border thickness * 2) */
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.counter-header {
    position: absolute;
    font-size: 15px;
    bottom: 10px;
    font-weight: 300;
}