.counter-button {
    position: absolute;
    background-color: transparent;
    width: 30px;
    height: 100%;
    font-size: 20px;
    border: none;
    color: black;
}

.counter-button:disabled {
   color: lightgrey;
}