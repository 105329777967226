.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

header {
  height: 10vh;
  color: var(--yellow);
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.numbers-container {
  display: grid;
  grid: auto / auto auto auto ;
  column-gap: 5px;
  row-gap: 20px;
  justify-content: center;
}

footer {
  display: flex;
  height: 5vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
